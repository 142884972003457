import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill-image-uploader/src/style.less";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { uploadImage } from "./uploadImage";
import { base_url } from "../../base_url";
import axios from "axios";
import toast from "react-hot-toast";
const predefinedBadges = [
  {
    name: "Basic Package",
    url: "https://images.squarespace-cdn.com/content/v1/5c2864609f8770b74f18cbb8/1565118033789-1JYNE10IZU8U0ELLF2WL/Basic+Package.png?format=1000w",
  },
  {
    name: "Product Badge",
    url: "https://png.pngtree.com/png-clipart/20220222/original/pngtree-original-product-badge-png-image_7303903.png",
  },
  {
    name: "Premium Badge",
    url: "https://png.pngtree.com/png-clipart/20220602/original/pngtree-premium-badges-png-image_7885137.png",
  },
];
function EditRealEstate() {
  const [newPackage, setNewPackage] = useState({});
  const [newPackages, setNewPackages] = useState([]);
  const [openAddPackage, setOpenAddPackage] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);
  const [editPackage, setEditPackage] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);

  const navigate = useNavigate();
  const [newEstate, setNewEstate] = useState({});

  // m_sayed states
  const [oldStandars, setOldStanders] = useState([]);
  const [oldGuards, setOldGuards] = useState([]);
  const [oldImage, setOldImage] = useState(null);
  const [oldImages, setOldImages] = useState([]);

  const { id } = useParams();

  const handleSubmit = async () => {
    let image = null;
    setNewEstate((prev) => ({ ...prev, loading: true }));
    if (newEstate?.imageFile) {
      image = await uploadImage(newEstate?.imageFile);
      delete newEstate?.imageFile;
    }

    let chartsFiles = [];
    if (newEstate?.chartsFiles) {
      try {
        chartsFiles = await Promise.all(
          Object.keys(newEstate?.chartsFiles).map((key) =>
            uploadImage(newEstate?.chartsFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      delete newEstate?.chartsFiles;
    }

    const uploadImages = async () => {
      const gutterUploads = newEstate?.gutters?.length
        ? newEstate.gutters.map(async (item) => {
            if (item?.file) {
              const image = await uploadImage(item.file);
              item.src = image?.result?.image;
            }
          })
        : [];

      const guardUploads = newEstate?.guards?.length
        ? newEstate.guards.map(async (item) => {
            if (item?.file) {
              const image = await uploadImage(item.file);
              item.src = image?.result?.image;
            }
          })
        : [];

      // Combine both gutter and guard uploads
      await Promise.all([...gutterUploads, ...guardUploads]);

      // Continue with any further processing
    };

    await uploadImages();

    //concat standers

    const newEstates =
      newEstate?.gutters?.length >= 1
        ? [
            ...newEstate.gutters?.map((item) =>
              [item?.src, item?.price]?.join("**")
            ),
            ...oldStandars.map((item) =>
              [item?.image, item?.price]?.join("**")
            ),
          ]
        : [
            ...oldStandars.map((item) =>
              [item?.image, item?.price]?.join("**")
            ),
          ];
    //concat guards
    const newGuards = newEstate?.guards
      ? [
          ...newEstate.guards?.map((item) =>
            [item?.src, item?.price]?.join("**")
          ),
          ...oldGuards.map((item) => [item?.image, item?.price]?.join("**")),
        ]
      : [...oldGuards.map((item) => [item?.image, item?.price]?.join("**"))];

    //concat images

    const newImages = chartsFiles
      ? [
          ...chartsFiles?.map((file) => file?.result?.image),
          ...oldImages.map((image) => image.url),
        ]
      : [...newEstate.images.map((image) => image.url)];
    delete newEstate?.guards;
    delete newEstate?.gutters;
    delete newEstate?.standers;
    delete newEstate?.guaters;
    const newPacks = newPackages?.map((item) => item?.id)?.join("**");
    delete newEstate?.packages;
    axios
      .post(base_url + "real_states/update_one/" + id, {
        ...newEstate,
        image: oldImage ? oldImage : image?.result?.image,
        images: newImages?.join("**"),
        stans: newEstates?.join("**stans**"),
        guards: newGuards?.join("**guards**"),
        packages: newPacks,
      })
      .then((res) => {
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
      })
      .finally(() => {
        setNewEstate((prev) => ({ ...prev, loading: false }));
      });
  };

  const getData = () => {
    axios.get(base_url + "real_states/get_details/" + id).then((res) => {
      setNewEstate(res?.result);
      setNewPackages(res?.result?.packages || []);
      //_____m_sayed
      setOldStanders(res?.result?.standers);
      setOldGuards(res?.result?.guaters);
      setOldImage(res?.result?.image);
      setOldImages(res?.result?.images);
      //_____end_m_sayed
    });
  };

  const openEditPackageModal = (pkg) => {
    setEditPackage(pkg);
    setOpenEditModal(true);
  };
  const updatePackage = async () => {
    let badge = null;
    setEditPackage((prev) => ({ ...prev, loading: true }));
    if (editPackage?.badgeFile) {
      badge = await uploadImage(editPackage?.badgeFile);
      delete editPackage?.badgeFile;
    }
    let images = [];
      console.log(editPackage?.imagesFiles);
      if (editPackage?.imagesFiles) {
      try {
        // Use Promise.all to handle multiple asynchronous uploads
        images = await Promise.all(
          Object.keys(editPackage?.imagesFiles).map((key) =>
            uploadImage(editPackage?.imagesFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      delete editPackage?.imagesFiles;
    }
    await axios
      .post(base_url + "packages/update_one/" + editPackage.id, {
        ...editPackage,
        badge: badge ? badge?.result?.image : editPackage?.badge,
        real_state_id: id,
        images: editPackage?.images?.length&&editPackage?.images?images?.map((item) => item?.result?.image)?.join("**")+"**"+editPackage?.images?.map(item=>item?.url)?.join("**"):images?.map((item) => item?.result?.image)?.join("**"),
        features: editPackage?.features
          ?.map((item) => [item?.name, item?.checked ? 1 : 0]?.join("*inc*"))
          ?.join("**feat**"),
      })
      .then((res) => {
        getData();
        // const updatedPackages = newPackages.map((pkg) =>
        //   pkg.id === editPackage.id ? res.result : pkg
        // );
        // setNewPackages(updatedPackages);
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
        setOpenEditModal(false);
      })
      .finally(() => {
        setEditPackage((prev) => ({ ...prev, loading: false }));
      });
  };
  const handleDeletePackage = async () => {
    await axios
      .delete(base_url + "packages/delete_one/" + packageToDelete)
      .then((res) => {
        setNewPackages(newPackages.filter((pkg) => pkg.id !== packageToDelete));
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
        setOpenDeleteModal(false);
      });
  };

  const addPackage = async () => {
    let badge = null;
    setNewPackage((prev) => ({ ...prev, loading: true }));
    if (newPackage?.badgeFile) {
      badge = await uploadImage(newPackage?.badgeFile);
      delete newPackage?.badgeFile;
    }

    let images = [];
    if (newAccordion?.imagesFiles) {
      try {
        // Use Promise.all to handle multiple asynchronous uploads
        images = await Promise.all(
          Object.keys(newAccordion?.imagesFiles).map((key) =>
            uploadImage(newAccordion?.imagesFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      delete newAccordion?.imagesFiles;
    }
    
    await axios
      .post(base_url + "packages/add_new", {
        ...newPackage,
        badge: badge ? badge?.result?.image : editPackage?.badge,
        images: editPackage?.images?.map((item) => item?.result?.image)?.join("**"),
        features: newAccordion?.features
          ?.map((item) => [item?.name, item?.checked ? 1 : 0]?.join("*inc*"))
          ?.join("**feat**"),
      })
      .then((res) => {
        setNewPackages([...newPackages, res?.result]);
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
      })
      .finally(() => {
        setNewPackage((prev) => ({ ...prev, loading: false }));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // m_sayed -----------------------------------

  // ============= Gutters ================== //
  const addGutter = () => {
    if (Array.isArray(newEstate?.gutters)) {
      setNewEstate((prevState) => ({
        ...prevState,
        gutters: [...prevState.gutters, { file: "", price: "" }],
      }));
    } else {
      setNewEstate((prevState) => ({
        ...prevState,
        gutters: [{ file: "", price: "" }],
      }));
    }
  };
  const removeGutter = (index) => {
    const updatedGutters = newEstate.gutters.filter(
      (gutters, i) => i !== index
    );
    setNewEstate((prevState) => ({
      ...prevState,
      gutters: updatedGutters,
    }));
  };
  const updateGutter = (index, field, value) => {
    const updatedGutters = newEstate.gutters.map((gutter, i) =>
      i === index ? { ...gutter, [field]: value } : gutter
    );
    newEstate.gutters.map((gutter, i) => {
      console.log(i === index ? { ...gutter, [field]: value } : gutter);
    });
    setNewEstate((prevState) => ({
      ...prevState,
      gutters: updatedGutters,
    }));
  };
  // ============= Gutters ================== //
  // =============  Guards ================== //
  const addGuard = () => {
    if (Array.isArray(newEstate?.guards)) {
      setNewEstate((prevState) => ({
        ...prevState,
        guards: [...prevState.guards, { file: "", price: "" }],
      }));
    } else {
      setNewEstate((prevState) => ({
        ...prevState,
        guards: [{ file: "", price: "" }],
      }));
    }
  };
  const removeGuard = (index) => {
    const updatedGuards = newEstate.guards.filter((guards, i) => i !== index);
    setNewEstate((prevState) => ({
      ...prevState,
      guards: updatedGuards,
    }));
  };
  const updateGuard = (index, field, value) => {
    const updatedGuards = newEstate.guards.map((guard, i) =>
      i === index ? { ...guard, [field]: value } : guard
    );
    newEstate.guards.map((guard, i) => {
      console.log(i === index ? { ...guard, [field]: value } : guard);
    });
    setNewEstate((prevState) => ({
      ...prevState,
      guards: updatedGuards,
    }));
  };


  const addPackageImage = (value) => {
    if(editPackage?.images?.length){
      setEditPackage((prevState) => ({
      ...prevState,
      imagesFiles: value,
    }));
    }else{
     setEditPackage((prevState) => ({
      ...prevState,
      imagesFiles:  value
    })); 
    }
    
  };
  const removePackageImages = (index) => {
    const updatedGuards = editPackage?.images.filter((guards, i) => i !== index);
    setEditPackage((prevState) => ({
      ...prevState,
      images: updatedGuards
    }));
  };

  // =============  Guards ================== //
  const [newAccordion, setNewAccordion] = useState({
    title: "",
    description: "",
    excution_time: "",
    price: "",
    badgeFile: null,
    imagesFiles: [],
    features: [],
  });

  //==================Feactures===============//
  const addFeature = () => {
    if(newAccordion?.features && newAccordion?.features?.length){
      setNewAccordion((prevState) => ({
        ...prevState,
        features: [...prevState.features, { name: "", checked: false }],
      }));
    }else{
      setNewAccordion((prevState) => ({
        ...prevState,
        features: [{ name: "", checked: false }],
      }));
    }
    
    if (editPackage) {
      if(editPackage?.features && editPackage?.features?.length){
        setEditPackage((prevState) => ({
          ...prevState,
          features: [...prevState.features, { name: "", checked: false }],
        }));
      }else{
        setEditPackage((prevState) => ({
          ...prevState,
          features: [{ name: "", checked: false }],
        }));
      }
      
   
    }
  };
  const removeOne = (index) => {
    const updatedFeatures = newAccordion.features.filter(
      (feature, i) => i !== index
    );
    setNewAccordion((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
    if (editPackage) {
      editPackage.features.filter((feature, i) => i !== index);
    }
  };
  const updateFeature = (index, field, value) => {
    const updatedFeatures = newAccordion.features.map((feature, i) =>
      i === index ? { ...feature, [field]: value } : feature
    );
  
    setNewAccordion((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
    if (editPackage) {
      const updatedFeatures = editPackage.features.map((feature, i) =>
        i === index ? { ...feature, [field]: value } : feature
      );
      
      setEditPackage((prevState) => ({
        ...prevState,
        features: updatedFeatures,
      }));
    }
  };
  const handleFileChange = (e, field) => {
    setNewAccordion((prevState) => ({
      ...prevState,
      [field]: e.target.files,
    }));
  };
  const handleEditFileChange = (e, field) => {
    setEditPackage((prevState) => ({
      ...prevState,
      [field]: e.target.files,
    }));
  };


  //--------old stander------------
  const removeOldStander = (index) => {
    const updatedOldStanders = oldStandars.filter((stander, i) => i !== index);
    setOldStanders(updatedOldStanders);
  };

  const updateOldStander = (index, field, value) => {
    const updatedStanders = oldStandars.map((stander, i) =>
      i === index ? { ...stander, [field]: value } : stander
    );

    setOldStanders(updatedStanders);
  };

  //--------old Guards------------

  const removeOldGuard = (index) => {
    const updatedOldGuards = oldGuards.filter((stander, i) => i !== index);
    setOldGuards(updatedOldGuards);
  };

  const updateOldGuard = (index, field, value) => {
    const updatedOldGuard = oldGuards.map((guard, i) =>
      i === index ? { ...guard, [field]: value } : guard
    );

    setOldGuards(updatedOldGuard);
  };

  //--------Old Image--------------
  const handleRemoveOldImage = () => {
    setOldImage(null);
  };

  const handleDeleteoldImage = (index) => {
    const updatedOldImages = oldImages.filter((image, i) => i !== index);
    setOldImages(updatedOldImages);
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Home" breadcrumbItem="Edit Real Estate" />
        <Row>
          <Col>
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <div className="rowDiv spaceBetween w-100 alignCenter">
                  <CardTitle className="h4">Edit Real-Estates</CardTitle>
                  <CardTitle
                    className="btn btn-success"
                    onClick={() => setOpenAddPackage(true)}
                  >
                    Add Package
                  </CardTitle>
                </div>
              </CardBody>
              <div className="columnDiv">
                <div className="columnDiv reactQuill">
                  <label htmlFor="">Description</label>
                  <ReactQuill
                    value={newEstate?.description}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        description: e,
                      });
                    }}
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    style={{ minHeight: "300px", color: "black" }}
                  />
                </div>
                <div className="columnDiv reactQuill">
                  <label htmlFor="">Terms And Conditions</label>
                  <ReactQuill
                    value={newEstate?.terms}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        terms: e,
                      });
                    }}
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    style={{ minHeight: "300px", color: "black" }}
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="uploadEstateImage">Image</label>
                  {oldImage && (
                    <div className="real_estate_image">
                      <img src={oldImage} alt="" />
                      <div
                        className="btn btn-sm btn-danger mt-2"
                        onClick={handleRemoveOldImage}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                  {!oldImage && (
                    <input
                      onChange={(e) => {
                        setNewEstate({
                          ...newEstate,
                          imageFile: e.target.files[0],
                        });
                      }}
                      className="form-control"
                      type="file"
                      name="uploadEstateImage"
                    />
                  )}
                </div>
                <div className="columnDiv">
                  <label htmlFor="uploadChartImage">Images</label>
                  <div className="real_estate_images">
                    {oldImages &&
                    oldImages?.length >= 1 &&
                    Array.isArray(oldImages)
                      ? oldImages?.map((image, index) => {
                          return (
                            <div key={index} className="real_estate_image">
                              <img src={image?.url} alt="" />
                              <div
                                onClick={() => handleDeleteoldImage(index)}
                                className="btn btn-sm btn-danger mt-2"
                              >
                                Delete
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <input
                    onChange={(e) => {
                      console.log(e.target.files);
                      setNewEstate({
                        ...newEstate,
                        chartsFiles: e.target.files,
                      });
                    }}
                    multiple
                    className="form-control"
                    type="file"
                    name="uploadChartImage"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="title">Title</label>
                  <input
                    value={newEstate?.title}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        title: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="title"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="proccessVideo">Proccess Video</label>
                  <input
                    value={newEstate?.proccessVideo}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        proccessVideo: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="proccessVideo"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="location">Location</label>
                  <input
                    value={newEstate?.location}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        location: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="location"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    value={newEstate?.end_date?.split(" ")[0]}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        end_date: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="date"
                    name="end_date"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="features">Standard Gutters</label>
                  <div className="real_estate_standars">
                    {oldStandars &&
                    oldStandars.length >= 1 &&
                    Array.isArray(oldStandars)
                      ? oldStandars?.map((stander, index) => {
                          return (
                            <div className="real_estate_standar d-flex align-items-center justify-content-between">
                              <div className="real_estate_standar_image real_estate_image">
                                <img src={stander.image} alt="" />
                              </div>
                              <div className="real_estate_standar_price d-flex align-items-center">
                                <input
                                  value={stander.price}
                                  type="text"
                                  onChange={(e) =>
                                    updateOldStander(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                />
                                <span
                                  onClick={() => removeOldStander(index)}
                                  style={{
                                    display: "flex",
                                    width: "30px",
                                    maxWidth: "30px",
                                    height: "30px",
                                    maxHeight: "30px",
                                    background: "red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "18px",
                                    color: "white",
                                    padding: "10px",
                                    fontWeight: "900",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    margin: "0 10px",
                                  }}
                                >
                                  -
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  {newEstate?.gutters?.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-row d-flex align-items-center mb-2"
                    >
                      <input
                        type="file"
                        onChange={(e) =>
                          updateGutter(index, "file", e.target.files[0])
                        }
                        placeholder="Gutter name"
                        className="form-control me-2"
                      />
                      <input
                        type="text"
                        onChange={(e) =>
                          updateGutter(index, "price", e.target.value)
                        }
                      />
                      <span
                        onClick={() => removeGutter(index)}
                        style={{
                          display: "flex",
                          width: "30px",
                          maxWidth: "30px",
                          height: "30px",
                          maxHeight: "30px",
                          background: "red",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: "white",
                          padding: "10px",
                          fontWeight: "900",
                          borderRadius: "50%",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={addGutter}
                  >
                    Add Standard Gutter
                  </button>
                </div>
                <div className="columnDiv">
                  <label htmlFor="features">Gutter Guard</label>
                  <div className="real_estate_standars">
                    {oldGuards &&
                    oldGuards.length >= 1 &&
                    Array.isArray(oldGuards)
                      ? oldGuards?.map((guater, index) => {
                          return (
                            <div
                              key={index}
                              className="real_estate_standar d-flex align-items-center justify-content-between"
                            >
                              <div className="real_estate_standar_image real_estate_image">
                                <img src={guater?.image} alt="" />
                              </div>
                              <div className="real_estate_standar_price d-flex align-items-center">
                                <input
                                  type="text"
                                  value={guater?.price}
                                  onChange={(e) =>
                                    updateOldGuard(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                />
                                <span
                                  onClick={() => removeOldGuard(index)}
                                  style={{
                                    display: "flex",
                                    width: "30px",
                                    maxWidth: "30px",
                                    height: "30px",
                                    maxHeight: "30px",
                                    background: "red",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "18px",
                                    color: "white",
                                    padding: "10px",
                                    fontWeight: "900",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    margin: "0 10px",
                                  }}
                                >
                                  -
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  {newEstate?.guards?.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-row d-flex align-items-center mb-2"
                    >
                      <input
                        type="file"
                        onChange={(e) =>
                          updateGuard(index, "file", e.target.files[0])
                        }
                        placeholder="Guard name"
                        className="form-control me-2"
                      />
                      <input
                        type="text"
                        onChange={(e) =>
                          updateGuard(index, "price", e.target.value)
                        }
                      />
                      <span
                        onClick={() => removeGuard(index)}
                        style={{
                          display: "flex",
                          width: "30px",
                          maxWidth: "30px",
                          height: "30px",
                          maxHeight: "30px",
                          background: "red",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: "white",
                          padding: "10px",
                          fontWeight: "900",
                          borderRadius: "50%",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={addGuard}
                  >
                    Add Gutter Guard
                  </button>
                </div>
                <div className="columnDiv">
                  <label htmlFor="userName">User Name</label>
                  <input
                    value={newEstate?.user_name}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        user_name: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="userName"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="password">Password</label>
                  <input
                    value={newEstate?.password}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        password: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="password"
                  />
                </div>
                {!newEstate?.loading ? (
                  <button
                    style={{ margin: "20px", marginLeft: "auto" }}
                    className="btn btn-primary"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </button>
                ) : (
                  "Loading..."
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <CardTitle className="h4">Packages</CardTitle>
                {newPackages.map((pkg) => (
                  <div
                    key={pkg.id}
                    className="d-flex flex-column mb-3 border p-3"
                  >
                    <h5>{pkg.title}</h5>
                    <p>
                      <strong>Description:</strong>{" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            pkg.description && pkg.description?.length
                              ? pkg.description
                              : "<p></p>",
                        }}
                      ></p>
                    </p>
                    <p>
                      <strong>Execution Time:</strong> {pkg.excution_time}
                    </p>
                    <p>
                      <strong>Price:</strong> {pkg.price}
                    </p>
                    {pkg.badge && (
                      <p>
                        <strong>Badge:</strong>
                        <img
                          src={pkg.badge}
                          alt="badge"
                          style={{ maxHeight: "50px", marginLeft: "10px" }}
                        />
                      </p>
                    )}
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => {
                          setPackageToDelete(pkg.id);
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() =>
                          openEditPackageModal({
                            ...pkg,
                            features: pkg?.features?.map((item, index) => ({
                              name: item?.feature_name,
                              checked: item?.include,
                              id:index 
                            })),
                          })
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Add Modal */}
      <Modal
        isOpen={openAddPackage}
        toggle={() => {
          setOpenAddPackage(false);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setOpenAddPackage(false);
          }}
        >
          {" "}
          Add Package{" "}
        </ModalHeader>
        <div className="tablelist-form">
          <ModalBody>
            <div className="columnDiv">
              <label htmlFor="title">Title</label>
              <input
                value={newPackage?.title}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    title: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="title"
              />
            </div>
            <div className="columnDiv reactQuill">
              <label htmlFor="">Description</label>
              <ReactQuill
                value={newPackage?.description}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    description: e,
                  });
                }}
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                style={{ minHeight: "300px", color: "black" }}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="excution_time">Time</label>
              <input
                value={newPackage?.excution_time}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    excution_time: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="excution_time"
                placeholder={"Ex. 2 Months"}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="price">Price</label>
              <input
                value={newPackage?.price}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    price: e.target.value,
                  });
                }}
                className="form-control"
                type="number"
                name="price"
                placeholder={"Ex. 2000000"}
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="badgeFile">Badge</label>
              <input
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    badgeFile: e.target.files[0],
                  });
                }}
                className="form-control"
                type="file"
                name="badgeFile"
              />
              <div className="rowDiv">
                {predefinedBadges?.map((item) => {
                  return (
                    <img
                      src={item?.url}
                      width={120}
                      alt={item?.name}
                      onClick={() => {
                        // alert(item?.url)
                        setNewPackage({
                          ...newPackage,
                          badge: item?.url,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="columnDiv mt-3">
              <label htmlFor="imagesFiles">Images</label>
              <input
                onChange={(e) => handleFileChange(e, "imagesFiles")}
                multiple
                className="form-control"
                type="file"
                name="imagesFiles"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="features">Features</label>
              {newAccordion.features.map((feature, index) => (
                <div
                  key={index}
                  className="feature-row d-flex align-items-center mb-2"
                >
                  <input
                    type="text"
                    value={feature.name}
                    onChange={(e) =>
                      updateFeature(index, "name", e.target.value)
                    }
                    placeholder="Feature name"
                    className="form-control me-2"
                  />
                  <input
                    type="checkbox"
                    checked={feature.checked}
                    value={feature.checked}
                    onClick={(e) => {
                      updateFeature(index, "checked", !feature.checked);
                    }}
                    name={`feature-${index}`}
                  />
                  <span
                    onClick={() => removeOne(index)}
                    style={{
                      display: "flex",
                      width: "30px",
                      maxWidth: "30px",
                      height: "30px",
                      maxHeight: "30px",
                      background: "red",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "white",
                      padding: "10px",
                      fontWeight: "900",
                      borderRadius: "50%",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    -
                  </span>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={addFeature}
              >
                Add Feature
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setOpenAddPackage(false)}
              >
                Close
              </button>
              {!newPackage?.loading ? (
                <button
                  type="submit"
                  onClick={() => addPackage()}
                  className="btn btn-success"
                  id="add-btn"
                >
                  Add
                </button>
              ) : (
                "Loading..."
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setOpenDeleteModal(false)}
        >
          Confirm Deletion
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this package?</ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleDeletePackage}
              className="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openEditModal}
        toggle={() => setOpenEditModal(false)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setOpenEditModal(false)}
        >
          Edit Package
        </ModalHeader>
        <div className="tablelist-form">
          <ModalBody>
            <div className="columnDiv">
              <label htmlFor="title">Title</label>
              <input
                value={editPackage?.title || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    title: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="title"
              />
            </div>
            <div className="columnDiv reactQuill">
              <label htmlFor="">Description</label>
              <ReactQuill
                value={editPackage?.description || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    description: e,
                  });
                }}
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                style={{ minHeight: "300px", color: "black" }}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="excution_time">Time</label>
              <input
                value={editPackage?.excution_time || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    excution_time: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="excution_time"
                placeholder={"Ex. 2 Months"}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="price">Price</label>
              <input
                value={editPackage?.price || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    price: e.target.value,
                  });
                }}
                className="form-control"
                type="number"
                name="price"
                placeholder={"Ex. 2000000"}
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="badgeFile">Badge</label>
              <input
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    badgeFile: e.target.files[0],
                  });
                }}
                className="form-control"
                type="file"
                name="badgeFile"
              />
              <div className="rowDiv">
                {predefinedBadges?.map((item) => {
                  return (
                    <img
                      src={item?.url}
                      width={120}
                      alt={item?.name}
                      onClick={() =>
                        setEditPackage({
                          ...editPackage,
                          badge: item?.url,
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className="columnDiv mt-3">
              {/* <label htmlFor="imagesFiles">Images</label>
              <input
                onChange={(e) => handleEditFileChange(e, "imagesFiles")}
                multiple
                className="form-control"
                type="file"
                name="imagesFiles"
              /> */}
            </div>
            
                    <div
                      className="feature-row d-flex align-items-center mb-2"
                    >
                      <input
                        type="file"
                        multiple={true}
                        onChange={(e) =>
                          addPackageImage(e.target.files)
                        }
                        className="form-control me-2"
                      />
                     
                      
                    </div>
                <>
                {
                        editPackage?.images?.map((feature, index) => {
                          return <div className="images">
                            <img src={feature?.url} alt="" />
  <span
                          onClick={() => removePackageImages(index)}
                          style={{
                            display: "flex",
                            width: "30px",
                            maxWidth: "30px",
                            height: "30px",
                            maxHeight: "30px",
                            background: "red",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "18px",
                            color: "white",
                            padding: "10px",
                            fontWeight: "900",
                            borderRadius: "50%",
                            cursor: "pointer",
                            margin: "0 10px",
                          }}
                        >
                          -
                        </span>
                          </div> 
                        
                        }

                        )}
                      </>
            <div className="columnDiv">
              <label htmlFor="features">Features</label>
              {editPackage?.features?.map((feature, index) => (
                <div
                  key={index}
                  className="feature-row d-flex align-items-center mb-2"
                >
                  <input
                    type="text"
                    value={feature.name}
                    onChange={(e) =>
                      updateFeature(index, "name", e.target.value)
                    }
                    placeholder="Feature name"
                    className="form-control me-2"
                  />
                  <input
                    type="checkbox"
                    checked={feature.checked}
                    value={feature.checked}
                    onClick={(e) => {
                      updateFeature(index, "checked", !feature.checked);
                    }}
                    name={`feature-${index}`}
                  />
                  <span
                    onClick={() => removeOne(index)}
                    style={{
                      display: "flex",
                      width: "30px",
                      maxWidth: "30px",
                      height: "30px",
                      maxHeight: "30px",
                      background: "red",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "white",
                      padding: "10px",
                      fontWeight: "900",
                      borderRadius: "50%",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    -
                  </span>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={addFeature}
              >
                Add Feature
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setOpenEditModal(false)}
              >
                Close
              </button>
              {!editPackage?.loading ? (
                <button
                  type="submit"
                  onClick={() => updatePackage()}
                  className="btn btn-success"
                >
                  Save Changes
                </button>
              ) : (
                "Loading..."
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}

export default EditRealEstate;
