import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import "./style.css";
import { useNavigate } from "react-router-dom";
import "react-quill-image-uploader/src/style.less";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { uploadImage } from "./uploadImage";
import { base_url } from "../../base_url";
import axios from "axios";
import toast from "react-hot-toast";
function AddRealEstate() {
  const predefinedBadges = [
    {
      name: "Basic Package",
      url: "https://images.squarespace-cdn.com/content/v1/5c2864609f8770b74f18cbb8/1565118033789-1JYNE10IZU8U0ELLF2WL/Basic+Package.png?format=1000w",
    },
    {
      name: "Product Badge",
      url: "https://png.pngtree.com/png-clipart/20220222/original/pngtree-original-product-badge-png-image_7303903.png",
    },
    {
      name: "Premium Badge",
      url: "https://png.pngtree.com/png-clipart/20220602/original/pngtree-premium-badges-png-image_7885137.png",
    },
  ];
  const navigate = useNavigate();
  const [newEstate, setNewEstate] = useState({});
  const [newPackage, setNewPackage] = useState({});
  const [newPackages, setNewPackages] = useState([]);
  const [openAddPackage, setOpenAddPackage] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);
  const [editPackage, setEditPackage] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [accordionManage, setAccordionManage] = useState({
    openAdd: false,
    openEdit: false,
    openDelete: false,
  });
  const [newAccordion, setNewAccordion] = useState({
    title: "",
    description: "",
    excution_time: "",
    price: "",
    badgeFile: null,
    imagesFiles: [],
    features: [],
  });

  const addFeature = () => {
    setNewAccordion((prevState) => ({
      ...prevState,
      features: [...prevState.features, { name: "", checked: false }],
    }));
    if (editPackage) {
      setEditPackage((prevState) => ({
        ...prevState,
        features: [...prevState.features, { name: "", checked: false }],
      }));
    }
  };
  const removeOne = (index) => {
    const updatedFeatures = newAccordion.features.filter(
      (feature, i) => i !== index
    );
    setNewAccordion((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
    if (editPackage) {
      editPackage.features.filter((feature, i) => i !== index);
    }
  };
  const updateFeature = (index, field, value) => {
    const updatedFeatures = newAccordion.features.map((feature, i) =>
      i === index ? { ...feature, [field]: value } : feature
    );
    newAccordion.features.map((feature, i) => {
      console.log(i === index ? { ...feature, [field]: value } : feature);
    });
    setNewAccordion((prevState) => ({
      ...prevState,
      features: updatedFeatures,
    }));
    if (editPackage) {
      const updatedFeatures = editPackage.features.map((feature, i) =>
        i === index ? { ...feature, [field]: value } : feature
      );
      editPackage.features.map((feature, i) => {
        console.log(i === index ? { ...feature, [field]: value } : feature);
      });
      setEditPackage((prevState) => ({
        ...prevState,
        features: updatedFeatures,
      }));
    }
  };

  const handleFileChange = (e, field) => {
    setNewAccordion((prevState) => ({
      ...prevState,
      [field]: e.target.files,
    }));
  };

  const handleSubmit = async () => {
    let image = null;
    setNewEstate((prev) => ({ ...prev, loading: true }));

    if (newEstate?.imageFile) {
      image = await uploadImage(newEstate?.imageFile);
      delete newEstate?.imageFile;
    }

    let chartsFiles = [];
    if (newEstate?.chartsFiles) {
      try {
        chartsFiles = await Promise.all(
          Object.keys(newEstate?.chartsFiles).map((key) =>
            uploadImage(newEstate?.chartsFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      delete newEstate?.chartsFiles;
    }

    const uploadImages = async () => {
      const gutterUploads = newEstate?.gutters?.length
        ? newEstate.gutters.map(async (item) => {
            if (item?.file) {
              const image = await uploadImage(item.file);
              item.src = image?.result?.image;
            }
          })
        : [];

      const guardUploads = newEstate?.guards?.length
        ? newEstate.guards.map(async (item) => {
            if (item?.file) {
              const image = await uploadImage(item.file);
              item.src = image?.result?.image;
            }
          })
        : [];

      // Combine both gutter and guard uploads
      await Promise.all([...gutterUploads, ...guardUploads]);

      // Continue with any further processing
    };

    // Call the function
    await uploadImages();

    console.log(newEstate?.guards);
    axios
      .post(base_url + "real_states/add_new", {
        ...newEstate,
        image: image?.result?.image,
        packages: newPackages?.map((item) => item?.id)?.join("**"),
        images: chartsFiles?.map((file) => file?.result?.image)?.join("**"),
        stans: newEstate.gutters
          ?.map((item) => [item?.src, item?.price]?.join("**"))
          ?.join("**stans**"),
        guards: newEstate.guards
          ?.map((item) => [item?.src, item?.price]?.join("**"))
          ?.join("**guards**"),
      })
      .then((res) => {
        console.log(res);
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
      })
      .finally(() => {
        setNewEstate((prev) => ({ ...prev, loading: false }));
      });
  };

  const addAccordion = async () => {
    let image = null;
    setNewAccordion((prev) => ({ ...prev, loading: true }));

    if (newAccordion?.imageFile) {
      image = await uploadImage(newAccordion?.imageFile);
      delete newAccordion?.imageFile;
    }

    let chartsFiles = [];
    if (newAccordion?.chartsFiles) {
      try {
        // Use Promise.all to handle multiple asynchronous uploads
        chartsFiles = await Promise.all(
          Object.keys(newAccordion?.chartsFiles).map((key) =>
            uploadImage(newAccordion?.chartsFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      delete newAccordion?.chartsFiles;
    }

    axios
      .post(base_url + "", {
        ...newAccordion,
        image: image?.result?.image,
      })
      .then((res) => {
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
      })
      .finally(() => {
        setNewAccordion((prev) => ({ ...prev, loading: false }));
      });
  };

  const addPackage = async () => {
    let badge = null;
    setNewPackage((prev) => ({ ...prev, loading: true }));
    if (newPackage?.badgeFile) {
      badge = await uploadImage(newPackage?.badgeFile);
      delete newPackage?.badgeFile;
    }

    let images = [];
    if (newAccordion?.imagesFiles) {
      try {
        // Use Promise.all to handle multiple asynchronous uploads
        images = await Promise.all(
          Object.keys(newAccordion?.imagesFiles).map((key) =>
            uploadImage(newAccordion?.imagesFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      console.log(images);
      delete newAccordion?.imagesFiles;
    }
    await axios
      .post(base_url + "packages/add_new", {
        ...newPackage,
        badge: badge ? badge?.result?.image : editPackage?.badge,
        images: images?.map((item) => item?.result?.image)?.join("**"),
        features: newAccordion?.features
          ?.map((item) => [item?.name, item?.checked ? 1 : 0]?.join("*inc*"))
          ?.join("**feat**"),
      })
      .then((res) => {
        setNewPackages([...newPackages, res?.result]);
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
      })
      .finally(() => {
        setNewPackage((prev) => ({ ...prev, loading: false }));
      });
  };

  const openEditPackageModal = (pkg) => {
    setEditPackage(pkg);
    setOpenEditModal(true);
  };
  const updatePackage = async () => {
    let badge = null;
    setEditPackage((prev) => ({ ...prev, loading: true }));
    if (editPackage?.badgeFile) {
      badge = uploadImage(editPackage?.badgeFile);
      delete editPackage?.badgeFile;
    }
    let images = [];
    if (editPackage?.imagesFiles) {
      try {
        // Use Promise.all to handle multiple asynchronous uploads
        images = await Promise.all(
          Object.keys(editPackage?.imagesFiles).map((key) =>
            uploadImage(editPackage?.imagesFiles[key])
          )
        );
      } catch (e) {
        console.log(e);
      }
      console.log(images);
      delete editPackage?.imagesFiles;
    }
    await axios
      .post(base_url + "packages/update_one/" + editPackage.id, {
        ...editPackage,
        badge: badge ? badge?.result?.image : editPackage?.badge,
        // real_state_id: id,
        images: images?.map((item) => item?.result?.image)?.join("**"),
        features: editPackage?.features
          ?.map((item) => [item?.name, item?.checked ? 1 : 0]?.join("*inc*"))
          ?.join("**feat**"),
      })
      .then((res) => {
        // getData();
        const updatedPackages = newPackages.map((pkg) =>
          pkg.id === editPackage.id ? res.result : pkg
        );
        setNewPackages(updatedPackages);
        console.log(res);
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
        setOpenEditModal(false);
      })
      .finally(() => {
        setEditPackage((prev) => ({ ...prev, loading: false }));
      });
  };
  const handleDeletePackage = async () => {
    await axios
      .delete(base_url + "packages/delete_one/" + packageToDelete)
      .then((res) => {
        setNewPackages(newPackages.filter((pkg) => pkg.id !== packageToDelete));
        toast.success(res?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        });
        setOpenDeleteModal(false);
      });
  };

  // ============= Gutters ================== //
  const addGutter = () => {
    if (Array.isArray(newEstate?.gutters)) {
      setNewEstate((prevState) => ({
        ...prevState,
        gutters: [...prevState.gutters, { file: "", price: "" }],
      }));
    } else {
      setNewEstate((prevState) => ({
        ...prevState,
        gutters: [{ file: "", price: "" }],
      }));
    }
  };
  const removeGutter = (index) => {
    const updatedGutters = newEstate.gutters.filter(
      (gutters, i) => i !== index
    );
    setNewEstate((prevState) => ({
      ...prevState,
      gutters: updatedGutters,
    }));
  };
  const updateGutter = (index, field, value) => {
    const updatedGutters = newEstate.gutters.map((gutter, i) =>
      i === index ? { ...gutter, [field]: value } : gutter
    );
    newEstate.gutters.map((gutter, i) => {
      console.log(i === index ? { ...gutter, [field]: value } : gutter);
    });
    setNewEstate((prevState) => ({
      ...prevState,
      gutters: updatedGutters,
    }));
  };
  // ============= Gutters ================== //
  // =============  Guards ================== //
  const addGuard = () => {
    if (Array.isArray(newEstate?.guards)) {
      setNewEstate((prevState) => ({
        ...prevState,
        guards: [...prevState.guards, { file: "", price: "" }],
      }));
    } else {
      setNewEstate((prevState) => ({
        ...prevState,
        guards: [{ file: "", price: "" }],
      }));
    }
  };
  const removeGuard = (index) => {
    const updatedGuards = newEstate.guards.filter((guards, i) => i !== index);
    setNewEstate((prevState) => ({
      ...prevState,
      guards: updatedGuards,
    }));
  };
  const updateGuard = (index, field, value) => {
    const updatedGuards = newEstate.guards.map((guard, i) =>
      i === index ? { ...guard, [field]: value } : guard
    );
    newEstate.guards.map((guard, i) => {
      console.log(i === index ? { ...guard, [field]: value } : guard);
    });
    setNewEstate((prevState) => ({
      ...prevState,
      guards: updatedGuards,
    }));
  };
  // =============  Guards ================== //
  const handleEditFileChange = (e, field) => {
    setEditPackage((prevState) => ({
      ...prevState,
      [field]: e.target.files,
    }));
  };
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Home" breadcrumbItem="Add Real Estate" />
        <Row>
          <Col>
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <div className="rowDiv spaceBetween w-100 alignCenter">
                  <CardTitle className="h4">Add Real-Estates</CardTitle>
                  <Row>
                    <CardTitle
                      className="btn btn-success"
                      onClick={() => setOpenAddPackage(true)}
                    >
                      Add Package
                    </CardTitle>
                    {/* <CardTitle
                      className="btn btn-success"
                      onClick={() =>
                        setAccordionManage({
                          ...accordionManage,
                          openAdd: true,
                        })
                      }
                    >
                      Add Accordion Packege
                    </CardTitle> */}
                  </Row>
                </div>
              </CardBody>
              <div className="columnDiv">
                <div className="columnDiv reactQuill">
                  <label htmlFor="">Description</label>
                  <ReactQuill
                    value={newEstate?.description}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        description: e,
                      });
                    }}
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    style={{ minHeight: "300px", color: "black" }}
                  />
                </div>
                <div className="columnDiv reactQuill">
                  <label htmlFor="">Terms And Conditions</label>
                  <ReactQuill
                    value={newEstate?.terms}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        terms: e,
                      });
                    }}
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],
                        [{ size: ["small", true, "large", "huge"] }],
                        ["link", "image"],
                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],
                      ],
                    }}
                    style={{ minHeight: "300px", color: "black" }}
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="uploadEstateImage">Image</label>
                  <input
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        imageFile: e.target.files[0],
                      });
                    }}
                    className="form-control"
                    type="file"
                    name="uploadEstateImage"
                  />
                </div>

                <div className="columnDiv">
                  <label htmlFor="uploadChartImage">Images</label>
                  <input
                    onChange={(e) => {
                      console.log(e.target.files);
                      setNewEstate({
                        ...newEstate,
                        chartsFiles: e.target.files,
                      });
                    }}
                    multiple
                    className="form-control"
                    type="file"
                    name="uploadChartImage"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="title">Title</label>
                  <input
                    value={newEstate?.title}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        title: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="title"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="proccessVideo">Proccess Video</label>
                  <input
                    value={newEstate?.proccessVideo}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        proccessVideo: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="proccessVideo"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="location">Location</label>
                  <input
                    value={newEstate?.location}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        location: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="location"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    value={newEstate?.end_date}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        end_date: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="date"
                    name="end_date"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="features">Standard Gutters</label>
                  {newEstate?.gutters?.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-row d-flex align-items-center mb-2"
                    >
                      <input
                        type="file"
                        onChange={(e) =>
                          updateGutter(index, "file", e.target.files[0])
                        }
                        placeholder="Gutter name"
                        className="form-control me-2"
                      />
                      <input
                        type="text"
                        onChange={(e) =>
                          updateGutter(index, "price", e.target.value)
                        }
                      />
                      <span
                        onClick={() => removeGutter(index)}
                        style={{
                          display: "flex",
                          width: "30px",
                          maxWidth: "30px",
                          height: "30px",
                          maxHeight: "30px",
                          background: "red",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: "white",
                          padding: "10px",
                          fontWeight: "900",
                          borderRadius: "50%",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={addGutter}
                  >
                    Add Standard Gutter
                  </button>
                </div>

                <div className="columnDiv">
                  <label htmlFor="features">Gutter Guard</label>
                  {newEstate?.guards?.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-row d-flex align-items-center mb-2"
                    >
                      <input
                        type="file"
                        onChange={(e) =>
                          updateGuard(index, "file", e.target.files[0])
                        }
                        placeholder="Guard name"
                        className="form-control me-2"
                      />
                      <input
                        type="text"
                        onChange={(e) =>
                          updateGuard(index, "price", e.target.value)
                        }
                      />
                      <span
                        onClick={() => removeGuard(index)}
                        style={{
                          display: "flex",
                          width: "30px",
                          maxWidth: "30px",
                          height: "30px",
                          maxHeight: "30px",
                          background: "red",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: "white",
                          padding: "10px",
                          fontWeight: "900",
                          borderRadius: "50%",
                          cursor: "pointer",
                          margin: "0 10px",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={addGuard}
                  >
                    Add Gutter Guard
                  </button>
                </div>
                <div className="columnDiv">
                  <label htmlFor="userName">User Name</label>
                  <input
                    value={newEstate?.user_name}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        user_name: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="userName"
                  />
                </div>
                <div className="columnDiv">
                  <label htmlFor="password">Password</label>
                  <input
                    value={newEstate?.password}
                    onChange={(e) => {
                      setNewEstate({
                        ...newEstate,
                        password: e.target.value,
                      });
                    }}
                    className="form-control"
                    type="text"
                    name="password"
                  />
                </div>
                {!newEstate?.loading ? (
                  <button
                    style={{ margin: "20px", marginLeft: "auto" }}
                    className="btn btn-primary"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </button>
                ) : (
                  "Loading..."
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ padding: "10px" }}>
              <CardBody>
                <CardTitle className="h4">Packages</CardTitle>
                {newPackages.map((pkg) => (
                  <div
                    key={pkg.id}
                    className="d-flex flex-column mb-3 border p-3"
                  >
                    <h5>{pkg.title}</h5>
                    <p>
                      <strong>Description:</strong>{" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            pkg.description && pkg.description?.length
                              ? pkg.description
                              : "<p></p>",
                        }}
                      ></p>
                    </p>
                    <p>
                      <strong>Execution Time:</strong> {pkg.excution_time}
                    </p>
                    <p>
                      <strong>Price:</strong> {pkg.price}
                    </p>
                    {pkg.badge && (
                      <p>
                        <strong>Badge:</strong>
                        <img
                          src={pkg.badge}
                          alt="badge"
                          style={{ maxHeight: "50px", marginLeft: "10px" }}
                        />
                      </p>
                    )}
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => {
                          setPackageToDelete(pkg.id);
                          setOpenDeleteModal(true);
                        }}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() =>
                          openEditPackageModal({
                            ...pkg,
                            features: pkg?.features?.map((item, index) => ({
                              name: item?.feature_name,
                              checked: item?.include,
                              id: index,
                            })),
                          })
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Add Modal */}
      <Modal
        isOpen={openAddPackage}
        toggle={() => {
          setOpenAddPackage(false);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setOpenAddPackage(false);
          }}
        >
          {" "}
          Add Package{" "}
        </ModalHeader>
        <div className="tablelist-form">
          <ModalBody>
            <div className="columnDiv">
              <label htmlFor="title">Title</label>
              <input
                value={newPackage?.title}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    title: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="title"
              />
            </div>
            <div className="columnDiv reactQuill">
              <label htmlFor="">Description</label>
              <ReactQuill
                value={newPackage?.description}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    description: e,
                  });
                }}
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                style={{ minHeight: "300px", color: "black" }}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="excution_time">Time</label>
              <input
                value={newEstate?.excution_time}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    excution_time: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="excution_time"
                placeholder={"Ex. 2 Monthes"}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="price">Price</label>
              <input
                value={newEstate?.price}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    price: e.target.value,
                  });
                }}
                className="form-control"
                type="number"
                name="price"
                placeholder={"Ex. 2000000"}
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="badgeFile">Badge</label>
              <input
                value={newEstate?.badgeFile}
                onChange={(e) => {
                  setNewPackage({
                    ...newPackage,
                    badgeFile: e.target.files[0],
                  });
                }}
                className="form-control"
                type="file"
                name="badgeFile"
              />
              <div className="rowDiv">
                {predefinedBadges?.map((item) => {
                  return (
                    <img
                      src={item?.url}
                      width={120}
                      alt={item?.name}
                      onClick={() => {
                        // alert(item?.url)
                        setNewPackage({
                          ...newPackage,
                          badge: item?.url,
                        });
                      }}
                    />
                  );
                })}
              </div>
              <div className="columnDiv">
                <label htmlFor="imagesFiles">Images</label>
                <input
                  onChange={(e) => handleFileChange(e, "imagesFiles")}
                  multiple
                  className="form-control"
                  type="file"
                  name="imagesFiles"
                />
              </div>
              <div className="columnDiv">
                <label htmlFor="features">Features</label>
                {newAccordion.features.map((feature, index) => (
                  <div
                    key={index}
                    className="feature-row d-flex align-items-center mb-2"
                  >
                    <input
                      type="text"
                      value={feature.name}
                      onChange={(e) =>
                        updateFeature(index, "name", e.target.value)
                      }
                      placeholder="Feature name"
                      className="form-control me-2"
                    />
                    <input
                      type="checkbox"
                      checked={feature.checked}
                      value={feature.checked}
                      onClick={(e) => {
                        updateFeature(index, "checked", !feature.checked);
                      }}
                      name={`feature-${index}`}
                    />
                    <span
                      onClick={() => removeOne(index)}
                      style={{
                        display: "flex",
                        width: "30px",
                        maxWidth: "30px",
                        height: "30px",
                        maxHeight: "30px",
                        background: "red",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        color: "white",
                        padding: "10px",
                        fontWeight: "900",
                        borderRadius: "50%",
                        cursor: "pointer",
                        margin: "0 10px",
                      }}
                    >
                      -
                    </span>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-secondary mt-2"
                  onClick={addFeature}
                >
                  Add Feature
                </button>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setOpenAddPackage(false)}
              >
                Close
              </button>
              {!newPackage?.loading ? (
                <button
                  type="submit"
                  onClick={() => addPackage()}
                  className="btn btn-success"
                  id="add-btn"
                >
                  Add
                </button>
              ) : (
                "Loading..."
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setOpenDeleteModal(false)}
        >
          Confirm Deletion
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this package?</ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleDeletePackage}
              className="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openEditModal}
        toggle={() => setOpenEditModal(false)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setOpenEditModal(false)}
        >
          Edit Package
        </ModalHeader>
        <div className="tablelist-form">
          <ModalBody>
            <div className="columnDiv">
              <label htmlFor="title">Title</label>
              <input
                value={editPackage?.title || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    title: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="title"
              />
            </div>
            <div className="columnDiv reactQuill">
              <label htmlFor="">Description</label>
              <ReactQuill
                value={editPackage?.description || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    description: e,
                  });
                }}
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                style={{ minHeight: "300px", color: "black" }}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="excution_time">Time</label>
              <input
                value={editPackage?.excution_time || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    excution_time: e.target.value,
                  });
                }}
                className="form-control"
                type="text"
                name="excution_time"
                placeholder={"Ex. 2 Months"}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="price">Price</label>
              <input
                value={editPackage?.price || ""}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    price: e.target.value,
                  });
                }}
                className="form-control"
                type="number"
                name="price"
                placeholder={"Ex. 2000000"}
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="badgeFile">Badge</label>
              <input
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    badgeFile: e.target.files[0],
                  });
                }}
                className="form-control"
                type="file"
                name="badgeFile"
              />
              <div className="rowDiv">
                {predefinedBadges?.map((item) => {
                  return (
                    <img
                      src={item?.url}
                      width={120}
                      alt={item?.name}
                      onClick={() =>
                        setEditPackage({
                          ...editPackage,
                          badge: item?.url,
                        })
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className="columnDiv mt-3">
              <label htmlFor="imagesFiles">Images</label>
              <input
                onChange={(e) => handleEditFileChange(e, "imagesFiles")}
                multiple
                className="form-control"
                type="file"
                name="imagesFiles"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="features">Features</label>
              {editPackage?.features?.map((feature, index) => (
                <div
                  key={index}
                  className="feature-row d-flex align-items-center mb-2"
                >
                  <input
                    type="text"
                    value={feature.name}
                    onChange={(e) =>
                      updateFeature(index, "name", e.target.value)
                    }
                    placeholder="Feature name"
                    className="form-control me-2"
                  />
                  <input
                    type="checkbox"
                    checked={feature.checked}
                    value={feature.checked}
                    onClick={(e) => {
                      updateFeature(index, "checked", !feature.checked);
                    }}
                    name={`feature-${index}`}
                  />
                  <span
                    onClick={() => removeOne(index)}
                    style={{
                      display: "flex",
                      width: "30px",
                      maxWidth: "30px",
                      height: "30px",
                      maxHeight: "30px",
                      background: "red",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "white",
                      padding: "10px",
                      fontWeight: "900",
                      borderRadius: "50%",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    -
                  </span>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={addFeature}
              >
                Add Feature
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setOpenEditModal(false)}
              >
                Close
              </button>
              {!editPackage?.loading ? (
                <button
                  type="submit"
                  onClick={() => updatePackage()}
                  className="btn btn-success"
                >
                  Save Changes
                </button>
              ) : (
                "Loading..."
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal>

      {/* <Modal
        isOpen={accordionManage?.openAdd}
        toggle={() => {
          setAccordionManage({ ...accordionManage, openAdd: false });
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setAccordionManage({ ...accordionManage, openAdd: false });
          }}
        >
          Add Accordion
        </ModalHeader>
        <div className="tablelist-form">
          <ModalBody>
            <div className="columnDiv">
              <label htmlFor="title">Title</label>
              <input
                value={newAccordion.title}
                onChange={(e) =>
                  setNewAccordion({ ...newAccordion, title: e.target.value })
                }
                className="form-control"
                type="text"
                name="title"
              />
            </div>
            <div className="columnDiv reactQuill">
              <label htmlFor="description">Description</label>
              <ReactQuill
                value={newAccordion.description}
                onChange={(value) =>
                  setNewAccordion({ ...newAccordion, description: value })
                }
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", true, "large", "huge"] }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                  ],
                }}
                style={{ minHeight: "300px", color: "black" }}
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="excution_time">Time</label>
              <input
                value={newAccordion.excution_time}
                onChange={(e) =>
                  setNewAccordion({
                    ...newAccordion,
                    excution_time: e.target.value,
                  })
                }
                className="form-control"
                type="text"
                name="excution_time"
                placeholder="Ex. 2 Months"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="price">Price</label>
              <input
                value={newAccordion.price}
                onChange={(e) =>
                  setNewAccordion({ ...newAccordion, price: e.target.value })
                }
                className="form-control"
                type="number"
                name="price"
                placeholder="Ex. 2000000"
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="badgeFile">Badge</label>
              <input
                onChange={(e) =>
                  setNewAccordion({
                    ...newAccordion,
                    badgeFile: e.target.files[0],
                  })
                }
                className="form-control"
                type="file"
                name="badgeFile"
              />
              <div className="rowDiv">
                {predefinedBadges.map((item) => (
                  <img
                    key={item.name}
                    src={item.url}
                    width={120}
                    alt={item.name}
                    onClick={() =>
                      setNewAccordion({ ...newAccordion, badge: item.url })
                    }
                  />
                ))}
              </div>
            </div>
            <div className="columnDiv">
              <label htmlFor="imagesFiles">Images</label>
              <input
                onChange={(e) => handleFileChange(e, "imagesFiles")}
                multiple
                className="form-control"
                type="file"
                name="imagesFiles"
              />
            </div>

            <div className="columnDiv">
              <label htmlFor="features">Features</label>
              {newAccordion.features.map((feature, index) => (
                <div
                  key={index}
                  className="feature-row d-flex align-items-center mb-2"
                >
                  <input
                    type="text"
                    value={feature.name}
                    onChange={(e) =>
                      updateFeature(index, "name", e.target.value)
                    }
                    placeholder="Feature name"
                    className="form-control me-2"
                  />
                  <input
                    type="checkbox"
                    checked={feature.checked}
                    value={feature.checked}
                    onClick={(e) => {
                      updateFeature(index, "checked", !feature.checked);
                    }}
                    name={`feature-${index}`}
                  />
                  <span
                    onClick={() => removeOne(index)}
                    style={{
                      display: "flex",
                      width: "30px",
                      maxWidth: "30px",
                      height: "30px",
                      maxHeight: "30px",
                      background: "red",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      color: "white",
                      padding: "10px",
                      fontWeight: "900",
                      borderRadius: "50%",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    -
                  </span>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                onClick={addFeature}
              >
                Add Feature
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() =>
                  setAccordionManage({
                    ...accordionManage,
                    openAdd: false,
                  })
                }
              >
                Close
              </button>
              {!newAccordion.loading ? (
                <button
                  type="submit"
                  onClick={() => addAccordion(newAccordion)}
                  className="btn btn-success"
                >
                  Add
                </button>
              ) : (
                "Loading..."
              )}
            </div>
          </ModalFooter>
        </div>
      </Modal> */}
    </div>
  );
}

export default AddRealEstate;
